/* nunito-sans-200normal - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Nunito Sans Extra Light '),
    local('Nunito Sans-Extra Light'),
    url('./files/nunito-sans-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-sans-latin-200.woff') format('woff'); /* Modern Browsers */
}

/* nunito-sans-200italic - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src:
    local('Nunito Sans Extra Light italic'),
    local('Nunito Sans-Extra Lightitalic'),
    url('./files/nunito-sans-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-sans-latin-200italic.woff') format('woff'); /* Modern Browsers */
}

/* nunito-sans-300normal - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Nunito Sans Light '),
    local('Nunito Sans-Light'),
    url('./files/nunito-sans-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-sans-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* nunito-sans-300italic - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Nunito Sans Light italic'),
    local('Nunito Sans-Lightitalic'),
    url('./files/nunito-sans-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-sans-latin-300italic.woff') format('woff'); /* Modern Browsers */
}

/* nunito-sans-400normal - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Nunito Sans Regular '),
    local('Nunito Sans-Regular'),
    url('./files/nunito-sans-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-sans-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* nunito-sans-400italic - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Nunito Sans Regular italic'),
    local('Nunito Sans-Regularitalic'),
    url('./files/nunito-sans-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-sans-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* nunito-sans-600normal - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Nunito Sans SemiBold '),
    local('Nunito Sans-SemiBold'),
    url('./files/nunito-sans-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-sans-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* nunito-sans-600italic - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Nunito Sans SemiBold italic'),
    local('Nunito Sans-SemiBolditalic'),
    url('./files/nunito-sans-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-sans-latin-600italic.woff') format('woff'); /* Modern Browsers */
}

/* nunito-sans-700normal - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Nunito Sans Bold '),
    local('Nunito Sans-Bold'),
    url('./files/nunito-sans-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-sans-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* nunito-sans-700italic - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Nunito Sans Bold italic'),
    local('Nunito Sans-Bolditalic'),
    url('./files/nunito-sans-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-sans-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

/* nunito-sans-800normal - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Nunito Sans ExtraBold '),
    local('Nunito Sans-ExtraBold'),
    url('./files/nunito-sans-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-sans-latin-800.woff') format('woff'); /* Modern Browsers */
}

/* nunito-sans-800italic - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Nunito Sans ExtraBold italic'),
    local('Nunito Sans-ExtraBolditalic'),
    url('./files/nunito-sans-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-sans-latin-800italic.woff') format('woff'); /* Modern Browsers */
}

/* nunito-sans-900normal - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Nunito Sans Black '),
    local('Nunito Sans-Black'),
    url('./files/nunito-sans-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-sans-latin-900.woff') format('woff'); /* Modern Browsers */
}

/* nunito-sans-900italic - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Nunito Sans Black italic'),
    local('Nunito Sans-Blackitalic'),
    url('./files/nunito-sans-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/nunito-sans-latin-900italic.woff') format('woff'); /* Modern Browsers */
}

